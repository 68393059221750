// eslint-disable

import { datadogReportError } from 'events/datadog'
import { config } from 'utils/internationalization'
import { VanityData } from './types'
import { combinedVanities as vanities } from './vanities'

export interface IVanityConfig {
	[key: string]: VanityData
}

const combinedVanities = vanities as IVanityConfig

Object.keys(combinedVanities).forEach((vanityKey) => {
	if (!combinedVanities[vanityKey].klaviyoProperties) {
		combinedVanities[vanityKey].klaviyoProperties = {
			title: combinedVanities[vanityKey].heroSettings?.title || '',
			image: combinedVanities[vanityKey].heroSettings?.sources?.[0].src || 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/split-feature-3.png',
			name: combinedVanities[vanityKey].saleName.split('Sale')[0].trim(),
			discount: '###',
			mattressDiscount: '%%%',
			link: `https://www.eightsleep.com/${vanityKey}`,
		}
	}
})

export const vanity_utms = Object.keys(combinedVanities).reduce((acc, currentKey) => {
	return {
		...acc,
		[currentKey]: {
			utm_source: combinedVanities[currentKey].utm_source,
			utm_medium: combinedVanities[currentKey].utm_medium,
		},
	}
}, {})

export const generateVanityUrls = () => {
	return Object.keys(vanityConfig)
		.filter((path) => !vanityConfig[path].page || vanityConfig[path].page?.startsWith('*'))
		.map((path) => ({
			params: {
				t1: path.toLowerCase(),
			},
		}))
}

export const vanity_paths = () =>
	generateVanityUrls().flatMap((p) => {
		return config.allRegions.map((it) => `${it ? '/' + it : ''}/${p.params.t1}`)
	})

export const getVanityData = () => {
	if (window.location.search.includes('code=') && window.location.search.includes('50')) {
		try {
			const searchParams = new URLSearchParams(window.location.search)
			const code = searchParams.get('code').toLowerCase()
			const vanity = getVanityDataFromDiscountCode(code.replace('50', ''))
			if (vanity) {
				return { ...vanity, utm_source: searchParams.get('utm_source') || vanity.utm_source, promoCode: code }
			}
		} catch (e) {
			datadogReportError(e, 'Error extracting T1 code query param')
		}
	}

	const vanity_item = localStorage.getItem('vanity')
	if (vanity_item) {
		const key = vanity_item.split('|')[0]
		let result = { ...vanityConfig[key], page: key }
		const appliedDiscountCode = sessionStorage.getItem('appliedDiscountCode')
		if (appliedDiscountCode && appliedDiscountCode.startsWith(result.promoCode)) {
			result.promoCode = appliedDiscountCode
		}
		const searchParams = new URLSearchParams(window.location.search)
		const utm_source = searchParams.get('utm_source')
		if (utm_source) {
			result.utm_source = utm_source
		}
		return result
	}

	return null
}

export const setVanityData = (page: string) => {
	if (typeof window !== 'undefined') {
		const split = location.pathname.split('/')
		const pathname = split[split.length - 1] || split[split.length - 2]
		if (pathname && vanityConfig[pathname]) {
			localStorage.setItem('vanity', `${pathname}|${new Date().toISOString()}`)
		} else if (page && vanityConfig[page]) {
			localStorage.setItem('vanity', `${page}|${new Date().toISOString()}`)
		}
	}
}

export const getVanityDataFromDiscountCode = (discountCode: string) => {
	const vanityKey = Object.keys(combinedVanities).find((key) => vanityConfig[key].promoCode === discountCode)
	return vanityKey ? { ...combinedVanities[vanityKey], page: vanityKey } : null
}

export const VANITY_DISCOUNT_CODES = [...new Set(Object.values(combinedVanities).map((vanity) => vanity.promoCode))]

export const vanityConfig: IVanityConfig = combinedVanities
